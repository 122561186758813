<template>
  <div>
    <nav class="modatta-navbar-mobile modatta-navbar">
      <div class="row">
        <div class="start has-dropdown">
          <b-dropdown aria-role="list" class="menu-dropdown small" position="is-bottom-left">
            <template #trigger>
              <b-button class="toggle">
                <modatta-icon icon="hamburger" width="0.7" :rem="true"></modatta-icon>
              </b-button>
            </template>

            <b-dropdown-item v-for="(item, index) in nav" :key="index" has-link aria-role="listitem">
              <a :href="item.href" class="dropdown-item">
                <modatta-icon :icon="item.icon"></modatta-icon>
                <span>{{ getNavItemI18n(item) }}</span>
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="center">
          <div class="balance text-nowrap">
            <span class="nowrap-ellipsis">{{ balance }}</span>
            <span class="nowrap-ellipsis">{{ $t('menu_side.balance_label') }}</span>
          </div>
        </div>
        <div class="end">
          <b-dropdown aria-role="list" class="user-info small" position="is-bottom-left">
            <template #trigger>
              <div class="avatar">
                <img v-if="auth?.avatar" src="/img/avatar_default.png" alt="Authenticated user avatar" />
                <span v-else>{{ auth?.personal_name.charAt(0).toUpperCase() }}</span>
              </div>
              <modatta-icon icon="dropdown-arrow"></modatta-icon>
            </template>

            <b-dropdown-item v-for="(item, index) in navItems" :key="index" has-link aria-role="listitem">
              <a :href="item.href" class="dropdown-item">
                <modatta-icon :icon="item.icon"></modatta-icon>
                <span v-html="$t('settings.' + item.name)"></span>
              </a>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem" class="logout" @click.native.prevent="logout()">
              <modatta-icon icon="logout"></modatta-icon>
              {{ $t('menu_user_account.logout') }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </nav>
    <nav v-if="auth" class="modatta-navbar">
      <div class="start" :class="hasIcon || hasIcon == null ? 'has-icon' : 'null'">
        <modatta-icon
          v-if="hasIcon || hasIcon == null"
          :icon="hasIcon == null ? title.toLowerCase() : hasIcon.name"
          :height="24"
        ></modatta-icon>
        <h1 class="page-title">{{ title | capitalize }}</h1>
        <span v-if="offerType != null" class="offer-type"
          ><strong>/</strong> <span>{{ offerType }}</span></span
        >
      </div>
      <div class="end">
        <b-dropdown aria-role="list" class="user-info" position="is-bottom-left">
          <template #trigger>
            <div class="avatar">
              <img v-if="auth.avatar" src="/img/avatar_default.png" alt="Authenticated user avatar" />
              <span v-else>{{ auth.personal_name.charAt(0).toUpperCase() }}</span>
            </div>
            <div class="name">{{ auth.personal_name | firstName }}</div>
            <modatta-icon icon="dropdown-arrow"></modatta-icon>
          </template>

          <b-dropdown-item v-for="(item, index) in navItems" :key="index" has-link aria-role="listitem">
            <a :href="item.href" class="dropdown-item">
              <modatta-icon :icon="item.icon"></modatta-icon>
              <span v-html="$t('settings.' + item.name)"></span>
            </a>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" class="logout" @click.native.prevent="logout()">
            <modatta-icon icon="logout"></modatta-icon>
            {{ $t('menu_user_account.logout') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </nav>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import Utilities from '../helpers/utilities.js';

  export default {
    props: ['title', 'hasIcon', 'offerType'],
    data() {
      return {
        isLoading: true,
        balance: '',
        icon: '',
        nav: [
          { name: 'menu_dashboard', icon: 'dashboard', href: '/' },
          { name: 'menu_brands', icon: 'brands', href: '/brands' },
          { name: 'menu_offers', icon: 'campaigns', href: '/offers' },
          { name: 'menu_budget', icon: 'budget', href: '/budget' },
          //{ name: 'menu_integrations', icon: 'puzzle', href: '/integration' },
          { name: 'menu_account', icon: 'settings', href: '/settings#profile' },
          //{ name: 'menu_panels', icon: 'panels', href: '/panels' },
        ],
        navItems: [
          { name: 'menu_profile', icon: 'profile', href: '/settings#profile' },
          { name: 'menu_security', icon: 'shield', href: '/settings#security' },
          { name: 'menu_billing', icon: 'billing', href: '/settings#billing' },
          { name: 'menu_settings', icon: 'settings-profile', href: '/settings#other' },
        ],
      };
    },
    mounted() {
      this.getBalance().then(() => {
        const balance = this.user.user.balance.value;
        const currency = this.$t(`labels.${this.user.user.balance.currency}`);
        this.balance = isFinite(balance) && isFinite(balance) > 0
          ? Utilities.currency(balance, currency)
          : Utilities.currency(0, currency);

        this.isLoading = false
      });
    },
    computed: {
      ...mapState(['user', 'isSidenavCollapsed']),
      auth() {
        return this.user.user;
      },
    },
    methods: {
      ...mapMutations(['setSidenavCollapsed']),
      ...mapActions(['signOut', 'getBalance']),
      logout() {
        this.signOut().then(() => window.location.reload());
      },
      isNavItemActive(item) {
        return item.namePage === this.page;
      },
      getNavItemIcon(item, active) {
        return active ? `${item.icon}-active` : item.icon;
      },
      getNavItemI18n(item) {
        return this.$t('menu_side.' + item.name);
      },
    },
    filters: {
      capitalize: (s) => s.charAt(0).toUpperCase() + s.slice(1),
      firstName: (w) => w.replace(/ .*/, ''),
    },
  };
</script>

<style lang="scss" scoped>
  .modatta-navbar {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .modatta-navbar-mobile {
    display: block;
    background-color: #fff;
    border-bottom-right-radius: 1.5rem;
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -24px;

    padding-left: 32px;
    padding-right: 32px;

    @media (min-width: 576px) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @media screen and (min-width: 768px) {
      display: none;
    }

    padding-top: 24px;
    padding-bottom: 24px;

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 23px;

      .start {
        .button {
          padding: 1rem;
        }

        .modatta-icon {
          margin: 0;
        }
      }

      .center {
        flex: 1;
        align-items: center;
        display: flex;

        span {
          line-height: 100%;
        }

        .balance span:first-child {
          font-family: 'Silka Bold';
          font-size: 1rem;

          @media screen and (min-width: 576px) {
            font-size: 2rem;
          }

          font-weight: 700;
        }

        .balance span:last-child {
          font-family: 'Silka Regular';
          font-size: 0.8rem;
          font-weight: 400;
        }
      }
    }

    @media (min-width: 768px) {
      display: none;
    }
  }
</style>
