<template>
  <div class="hide-on-mobile">
    <!-- Aside -->
    <aside class="modatta-side-nav no-scrollbar" :class="{ 'is-collapsed': isSidenavCollapsed }">
      <div class="modatta-side-wrap">
        <!-- Toggle -->
        <div class="slot">
          <b-button class="toggle" @click="setSidenavCollapsed(!isSidenavCollapsed)">
            <modatta-icon icon="hamburger" width="0.7" :rem="true"></modatta-icon>
          </b-button>
        </div>

        <!-- Balance -->
        <b-tooltip
          v-bind:label="balance"
          type="is-dark"
          position="is-right"
          size="is-small"
          :class="{ 'hide-tooltip': !isSidenavCollapsed }"
        >
          <div class="slot">
            <div class="balance text-nowrap">
              <span v-if="!isLoading" class="nowrap-ellipsis">{{ balance }}</span>
              <b-skeleton height="3em" :animated="true" :active="isLoading"></b-skeleton>
              <span class="nowrap-ellipsis">{{ $t('menu_side.balance_label') }}</span>
            </div>
          </div>
        </b-tooltip>

        <!-- Menu -->
        <div class="slot fill">
          <ul class="nav">
            <li v-for="(el, i) in nav" :key="i">
              <router-link
                :to="el.href"
                class="navigation-item"
                :class="{ 'is-active': isNavItemActive(el) }"
              >
                <div class="modatta-nav-icon" :class="[el.icon]"></div>
                <span class="text-ellipsis">{{ getNavItemI18n(el) }}</span>
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Footer -->
        <div class="slot copyright">
          <a href="https://www.modatta.org">
            <div class="modatta-logo"></div>
            <span class="nowrap-ellipsis">Copyright © {{ new Date().getFullYear() }} Modatta</span>
          </a>
        </div>
      </div>
    </aside>
    <!-- aside -->
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import Utilities from '../helpers/utilities.js';

  export default {
    props: ['page'],
    data() {
      return {
        isLoading: true,
        balance: '',
        nav: [
           { name: 'menu_dashboard', icon: 'dashboard', href: '/', namePage: 'dashboard' },
          { name: 'menu_brands', icon: 'brands', href: '/brands', namePage: 'brands' },
          { name: 'menu_offers', icon: 'campaigns', href: '/offers', namePage: 'campaigns' },
          //{ name: 'menu_panels', icon: 'panels', href: '/panels', namePage: 'panels' },
          //{ name: 'menu_integrations', icon: 'panels', href: '/integration', namePage: 'integration' },
          { name: 'menu_budget', icon: 'budget', href: '/budget', namePage: 'budget' },
          { name: 'menu_account', icon: 'settings', href: '/settings#profile', namePage: 'settings' },
        ],
        selectedOption: {},
      };
    },
    mounted() {
      this.getBalance().then(() => {
        const balance = this.user.user.balance.value;
        const currency = this.$t(`labels.${this.user.user.balance.currency}`);
        this.balance = isFinite(balance) && isFinite(balance) > 0
        ? Utilities.currency(balance, currency)
          : Utilities.currency(0, currency);

        this.isLoading = false
      });
    },
    computed: {
      ...mapState(['user', 'isSidenavCollapsed', 'openSideModal', 'modal']),
    },
    methods: {
      ...mapMutations(['setSidenavCollapsed']),
      ...mapActions(['getBalance']),
      ...mapMutations(['setOpenSideModal']),
      isNavItemActive(item) {
        return item.namePage === this.page;
      },
      getNavItemI18n(item) {
        return this.$t('menu_side.' + item.name);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .hide-on-mobile {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }
</style>
